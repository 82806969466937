<script setup lang="ts">import { PropType } from "vue";


type InputType = "text" | "password" | "number" | "input"
type Theme = "dark" | "normal" | "start"

const props = defineProps({
    theme: {
        type: String as PropType<Theme>,
        default: true
    },
    placeholder: {
        type: String,
        default: ""
    },
    autocomplete: {
        type: String,
    },
    modelValue: {
        type: String,
    },
    readonly: {
        type: Boolean,
        default: false
    },
    name: {
        type: String,
    },
    type: {
        type: String as PropType<InputType>,
        default: "text"
    },
})

const emit = defineEmits(["update:modelValue", "keypressEnter"])

const updateValue = (event: Event) => {
    const input = event.target as HTMLInputElement
    emit("update:modelValue", input.value)
}

const keypress = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
        emit('keypressEnter')
    }
}

</script>
<template>
    <input class="common" :name="name" :class="theme" :type="type" :value="modelValue" :placeholder="placeholder"
        :autocomplete="autocomplete" :readonly="readonly" @input="updateValue" @keypress="keypress"
        :data-qa="`common-input-${name}`">
</template>
<style scoped>
.common:active,
.common:focus {
    @apply border-0 outline-none border-none;
}

.common:read-only {
    @apply text-stone-400
}

.common {
    @apply h-10 cursor-pointer border-0;
}

.dark:active,
.dark:hover {
    @apply bg-stone-700
}

.dark {
    @apply rounded-xl p-5 text-stone-100 bg-black
}

.normal:active,
.normal:hover {
    @apply bg-stone-300
}

.normal {
    @apply rounded-xl p-5 text-stone-900 bg-stone-200
}

.start {
    @apply h-10 w-full cursor-pointer border-0 text-stone-900 bg-transparent
}
</style>
